import { PromotionClient } from '@shop25/banners-client'

document.addEventListener('DOMContentLoaded', () => {
  if (window.promotionClientOptions !== undefined) {
    const client = new PromotionClient(window.promotionClientOptions)

    const elements = document.querySelectorAll('.js-promotion-app')

    elements.forEach(async (element) => {
      const location = client.createLocation(element.dataset.locationName)
      await location.mount(element, element.dataset.filterValue || null)
    })
  }
})

